<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="courier-pointer"
        flexColumn
        navigation="courier-pointers"
        :onNew="createPointer"
        :onPullSuccessAsync="pullPointer"
        title="Courier Pointer"
        :defaultBladeWidth="700">
        <template slot-scope="{ item, data }">
            <v-toolbar>
                <v-btn-toggle
                    v-model="isFrom"
                    @change="infoPosition = isFrom ? pointer.boundaryFrom[2] : pointer.boundaryTo[2]"
                    class="primary mr-4"
                    mandatory>
                    <v-btn key="1" :value="true">From</v-btn>
                    <v-btn key="2" :value="false">To</v-btn>
                </v-btn-toggle>

                <v-btn-toggle
                    v-model="weekdays" 
                    multiple
                    class="primary"
                    @change="changeWeekdays">
                    <v-btn
                        v-for="wDay in weekdayOptions"
                        :key="wDay"
                        :value="wDay"
                        :disabled="!data.isEditing && !data.isNew">{{ wDay }}</v-btn>
                </v-btn-toggle>

            </v-toolbar>
            
            <BT-Snack v-model="msg" />

            <GmapMap
                :center="center"
                :zoom="7"
                style="width: 100%; height: 60vh;"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false }">
                    
                    <gmap-info-window
                        opened
                        :position="infoPosition">
                        <template v-slot:default>
                            <v-card class="transparent primary--text pa-0 ma-0">
                                <v-card-title class="ma-0 pa-0">{{ isFrom ? 'From' : 'To' }}</v-card-title>
                                <v-card-text class="ma-0 pa-0">
                                    <v-checkbox
                                        v-if="isFrom"
                                        v-model="item.preferStartPickup"
                                        dense
                                        :disabled="!data.isEditing && !data.isNew"
                                        hide-details
                                        label="Prefer pickup from clients"
                                        light />

                                    <v-checkbox
                                        v-if="!isFrom"
                                        v-model="item.preferEndDropoff"
                                        dense
                                        :disabled="!data.isEditing && !data.isNew"
                                        hide-details
                                        label="Prefer dropoff to clients"
                                        light />

                                </v-card-text>
                                <v-list-item v-if="!isFrom" dense>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="primary--text">Courier</v-list-item-subtitle>
                                        <v-list-item-title>
                                            <BT-Select-Dialog
                                                v-if="data.isEditing || data.isNew"
                                                buttonClass="primary--text"
                                                small
                                                dense
                                                icon="mdi-pencil"
                                                width="300"
                                                navigation="couriers"
                                                :returnObject="true"
                                                title="Couriers"
                                                :onFilter="filterCouriers"
                                                @change="selectCourier">
                                                <template slot-scope="{ item }">
                                                    <strong v-if="item != null">{{ item.seller.companyName }}</strong>
                                                </template>
                                            </BT-Select-Dialog>
                                            <strong v-if="item.isSelfCourier" class="primary--text">Self</strong>
                                            <strong v-else-if="item.courierAgreement != null" class="primary--text">{{ item.courierAgreement.seller.companyName }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </template>
                    </gmap-info-window>

                    <gmap-polygon
                        v-if="isFrom"
                        :draggable="data.isEditing || data.isNew"
                        :paths="item.boundaryFrom"
                        @paths_changed="($event) => { updatePointerPaths($event, item) }"
                        :editable="data.isEditing || data.isNew"
                        :fillOpacity="0.5"
                        class="polygon" />

                    <gmap-polygon
                        v-else
                        :draggable="data.isEditing || data.isNew"
                        :paths="item.boundaryTo"
                        @paths_changed="($event) => { updatePointerPaths($event, item) }"
                        :editable="data.isEditing || data.isNew"
                        :fillOpacity="0.5"
                        class="polygon" />

                </GmapMap>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Courier-Pointer-Blade',
    components: {
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue')
    },
    data: function() {
        return {
            center: { lat: -38, lng: 144 },
            infoPosition: null,
            isAlways: false,
            isFrom: true,
            isLoading: false,
            msg: null,
            pointer: null,
            weekdays: [],
            // weekdayOptions: ['Always', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        changeWeekdays(val) {
            if (this.isAlways && val.length > 1) {
                val = val.filter(y => y != 'Always');
            }
            if (!this.isAlways && val.some(y => y == 'Always')) {
                val = ['Always'];
            }

            this.isAlways = val.some(y => y == 'Always');
            
            this.pointer.weekdays = val.toString();
            this.weekdays = val;
        },
        createPointer() {
            return {
                id: null,
                weekdays: 'Always',
                boundaryFrom: this.getAreaToLeft(this.center, 0.5),
                boundaryTo: this.getAreaToRight(this.center, 0.5),
                isSelfCourier: true,
                preferStartPickup: true,
                preferEndDropoff: true,
                courierAgreementID: null,
                courierAgreement: null,
            };
        },
        filterCouriers(list) {
            var rList = this.copyDeep(list);
            rList.unshift({ id: 'self', seller: { companyName: 'I Am The Courier' } });
            return rList;
        },
        async pullPointer(pointer) {
            if (pointer != null) {
                if (pointer.weekdays != null) {
                    this.weekdays = pointer.weekdays.split(',');
                }
                else {
                    this.weekdays = [];
                }
                
                this.isAlways = this.weekdays.some(y => y == 'Always');
                this.pointer = pointer;

                this.infoPosition = this.isFrom ? pointer.boundaryFrom[2] : pointer.boundaryTo[2];
            }

            return pointer;
        },
        selectCourier(res) {
            if (res.id === 'self') {
                this.pointer.isSelfCourier = true;
            }
            else {
                this.pointer.isSelfCourier = false;
                this.pointer.courierAgreementID = res.id;
                this.pointer.courierAgreement = res;
            }
        },
        updatePointerPaths(mvcArray, pointer) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }

            if (this.isFrom) {
                pointer.boundaryFrom = paths[0];
                this.infoPosition = pointer.boundaryFrom[2];
            }
            else {
                pointer.boundaryTo = paths[0];
                this.infoPosition = pointer.boundaryTo[2];
            }
        },
    }
}
</script>